<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <h2 class="mb-2 text-primary">
            {{ $t('roles-details') }}
          </h2>
          <hr class="mb-2">
        </b-col>
        <b-col
          v-for="(item,key) in details"
          :key="key"
          md="4"
          lg="3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <p v-if="key === 'created_at' || key === 'updated_at'">
            {{ item ? formatDate(item) : '-' }}
          </p>
          <p v-else-if="key === 'display_name'">
            {{ item ? item[$i18n.locale] : '-' }}
          </p>
          <p v-else>
            {{ item || '-' }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <h2 class="mb-2 text-primary">
          {{ $t('permissions') }}
        </h2>
      </b-col>

      <b-col
        v-for="(item,k) in permissions"
        :key="item.name"
        md="6"
        lg="4"
      >
        <b-card no-body>
          <b-card-body>
            <b-card-title class="text-capitalize d-flex align-items-center text-primary">
              {{ k }}
            </b-card-title>
            <b-card-text>
              <div
                v-for="el in item"
                :key="el.display_name"
                class="mb-1 justify-content-between"
              >
                <h5 class="mb-0">
                  {{ el.display_name }}
                </h5>
                <b-row class="mt-1">
                  <b-col
                    cols="6"
                  >
                    {{ $t('can_delete') }} : <span class="text-primary">{{ el.actions.can_delete ? $t('yes') : $t('no') }}</span>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    {{ $t('can_update') }} : <span class="text-primary">{{ el.actions.can_update ? $t('yes') : $t('no') }}</span>
                  </b-col>
                </b-row>
                <hr>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: null,
      permissions: [],
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/roles/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = _.pick(res.data.data, [
            'name',
            'created_at',
            'updated_at',
          ])
          const permissions = res.data.data.permissions.map(permission => {
            const updatedPermission = { ...permission }

            updatedPermission.group = JSON.parse(updatedPermission.group)
            updatedPermission.display_name = JSON.parse(updatedPermission.display_name)

            updatedPermission.group = updatedPermission.group[this.$i18n.locale]
            updatedPermission.display_name = updatedPermission.display_name[this.$i18n.locale]

            return updatedPermission
          })
          this.permissions = _.groupBy(permissions, 'group')
        })
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
  },
}
</script>
